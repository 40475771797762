import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "./assets/styles/sass/global.scss";
import i18n from "./system/lang/lang.js";
import { createPinia } from "pinia";
import VueQRCodeComponent from 'vue-qrcode-component';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import {
  getMemberInfo, getStakingType, getGameList, getGameAccount, getPromotionList, getBannerList
} from "@/system/request/api/api";
import { handleError } from "@/system/request/handleRes";

Vue.use(VueGoodTablePlugin);
Vue.component('qr-code', VueQRCodeComponent);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(createPinia())

Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue();

new Vue({
  data() {
    return {
      banner1: require("./assets/images/homepage/banner1.png"),
      banner2: require("./assets/images/homepage/banner2.png"),
      isLoading: false,
      userInfo: {
        invest: 0,
        rank: {
          rank_name_en: ''
        }
      },
      currentAddress: '',
      username: '',
      page: 1,
      teamList: [],
      newsList: [],
      gameList: [],
      category: '1001',
      gameAmount: [],
      packageList: [],
      rewardList: [],
      promotionList: [],
      newsItem: {},
      assetInfo: {
        static_bonus: 0,
        special_bonus: 0,
        dynamic_bonus: 0,
        level_bonus: 0,
      },
      modal: {
        title: "",
        msg: "",
        action: false,
        error: false,
      },
      inv_link: ''
    }
  },
  watch: {
    '$i18n.locale': 'getNewsList'
  },
  methods: {
    getGame() {
      var result = getGameList(this.category)
      var self = this;
      self.isLoading = true;
      result
        .then(function (value) {
          var datalist = value.data.data.data;
          console.log(datalist);
          self.rows = datalist;

          self.totalRecords = value.data.data.total;

          self.gameList = value.data.data.game;

          // self.gameAmount = [];
          var token = localStorage.getItem('gamingToken') || '';
          if (token.length > 0) {
            for (let i = 0; i < self.gameList.length; i++) {
              self.getGameAccount(self.gameList[i].id, i);

            }

          } else {
            self.isLoading = false;

          }
        })
    },
    getGameAccount(gameid, index) {
      var result = getGameAccount(gameid)
      var self = this;
      self.isLoading = true;
      var datalist;
      result
        .then(function (value) {
          if (value.data.error_code == '0') {
            datalist = value.data.data;
            console.log(datalist);

            var found = self.gameAmount.some((el) => el.game.id == datalist.game.id);
            if (found) {
              self.gameAmount.forEach(element => {
                if (element.game.id == datalist.game.id) {
                  element = datalist;

                }

              });

            } else {
              self.gameAmount.push(datalist);

            }

          }

          if(index == self.gameList.length-1){
            self.isLoading = false;

          }

          // self.rows = datalist;
          // self.totalRecords = value.data.data.total;
          // self.isLoading = false;
        });
    },
    getPromotionList() {
      var token = localStorage.getItem('gamingToken') ?? ''
      if (token.length <= 0) {
        return;
      }
      this.$root.isLoading = true;
      var result = getPromotionList();

      var self = this;
      result
        .then(function (value) {
          self.promotionList = value.data.data.promotion;
          self.rewardList = value.data.data.reward;
          self.$root.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
        });
    },
    changeLan(lan) {
      if (lan == 'zh' || lan == 'en' || lan == 'zhHant' || lan == 'ja' || lan == 'ko') {
        localStorage.setItem('lan', lan);
        this.$i18n.locale = lan;

      } else {
        this.$root.makeToast("warning", this.$t('coming_soon'));
      }
    },
    getStakingType() {
      var self = this;
      self.isLoading = true;
      var result = getStakingType();
      result
        .then(function (value) {
          self.packageList = value.data.data.package_type;
          self.isLoading = false;


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getInfo() {
      var token = localStorage.getItem('gamingToken') ?? '';
      console.log(token);
      if (token.length <= 0) {
        return;
      }
      var self = this;
      self.isLoading = true;
      var result = getMemberInfo();
      self.inv_link = location.origin + "/register?ref_id=";
      result
        .then(function (value) {
          self.isLoading = false;
          if (value.data.error_code == '0') {
            self.userInfo = value.data.data;
            self.ref_code = value.data.data.refferal_code;
            self.inv_link = self.inv_link + self.ref_code;

          } else {
            self.makeToast("danger", self.$t(value.data.message), 'danger');

          }



        })
        .catch(function (error) {
          self.makeToast("warning", self.$t(handleError(error)), 'danger');
        });
    },
    getNewsList() {
      // this.$i18n.locale == 'zh' ? 'cn' : 'en'
      var result = getBannerList(this.page);
      var self = this;
      result
        .then(function (value) {

          self.newsList = value.data.data;
          console.log(self.newsList);


        })
        .catch(function (error) {
          console.log(error);
        });
    },
    makeToast(variant = null, msg, type) {
      this.msg = msg;
      this.variant = variant;
      this.type = type;
      this.$bvToast.toast(msg, {
        // // title: ` ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },
    checkKYC() {
      if (this.userInfo.check_kyc.can_withdraw == 'approved') {
        this.$bvModal.show('withdraw');

      } else {
        this.modal = {
          title: this.$t('kyc'),
          msg: this.$t('kyc_hint'),
          action: false,
          error: true,
        };
        this.$bvModal.show('modal-errorMsg');

      }
    },
    clearErrorMsg() {
      this.$root.modal = {
        title: "",
        msg: "",
        action: false,
        error: false,
      };
      console.log(this.modal);

    }
  },
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
