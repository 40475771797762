export function checkRes(data) {
    if (data.code > 0) {
        console.error(data.message);
        return false;
    } else {
        console.log(data);
        return true;

    }

}

export function handleError(error) {
    console.log(error)
    try {
        if (error.response.status == 500) {
            return 'internalError';

        } else if (error.response.status == 401) {
            localStorage.removeItem("gamingToken");
            setTimeout(() => {
                var current = location.origin + '/web';
                window.location.href = current;

            }, 2000);
            return 'unauthorized';

        } else {
            return 'internalError';

        }

    } catch (error) {
        return 'internalError';

    }

}