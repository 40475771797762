

export default async (to, from, next) => {
  var token = localStorage.getItem("gamingToken") || '';
  // var userInfo = localStorage.getItem("info") || '';


  // try {
  //   userInfo = JSON.parse(userInfo);
  // } catch (e) {
  //   userInfo = '';
  // }

  if (to.meta.requiresAuth) {
    if (token.length > 0) {
        next();
      // if (userInfo.password2 == null) {
      //   next('/web/set-sec-password');
      // } else {

      // }
    } else {
      localStorage.removeItem("gamingToken");
      next('/web/sessions/login');
    }

  } else {
    next();

  }
  
};
