<template>
    <div v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register')"
        class="footer_wrap mt-auto" ref="footer">
        <!-- Footer Start -->
        <div class="app-footer">
            <div class="" v-if="$root.userInfo.name">
                <div class="p-2">
                    <div class="d-flex justify-content-between">
                        <div class="text-secondary text-12">{{ $t('welcome') }} <span>{{ this.$root.userInfo.name }}</span></div>
                            <!-- <b-button variant="light">
                                <span class="text-white"> <i class="fa-solid fa-rotate-right mr-2"></i> {{ $t('reFresh')
                                    }}</span>
                            </b-button> -->
                        <img :src="require('./assets/images/homepage/wallet.png')" alt="" height="auto" width="24px">
                    </div>
                    <b class="text-special">{{ $t('my_balance') }} : RM {{ this.$root.userInfo.point }}</b>
                    <b-row>
                        <b-col>
                            <router-link tag="a" to="/web/fund/deposit">
                                <b-button class="bg-special font-weight-medium text-14 no-deco" block>
                                    {{ $t('deposit')}}         
                                </b-button>
                            </router-link>
                        </b-col>
                        <b-col>
                            <router-link tag="a" to="/web/fund/withdraw">
                                <b-button class="bg-special font-weight-medium text-14" block>                               
                                    {{ $t('withdraw') }}
                                </b-button>
                            </router-link>
                        </b-col>    
                    </b-row>
                        <!-- <p class="text-white text-left">
                            <span class="mr-3">{{ $t('deposit_min') }} : RM5.00</span>
                            <span class="mr-3">{{ $t('withdraw_min') }} : RM220.00</span>
                            <span class="mr-3">{{ $t('withdraw_max') }} : RM160.00</span>
                        </p> -->
            </div>
        </div>


            <!-- <b-row align-h="around" align-v="end" no-gutters class="mb-2">
                <b-col cols="2" class="position-relative">
                    <router-link tag="a" to="/web/homepage" class="nav-item-hold">
                        <div class="nav-icon">
                            <img :src="$parent.path == 'homepage' ? require('./assets/images/xlogo_footer/dashboard_active.png') : require('./assets/images/xlogo_footer/dashboard.png')"
                                class="nav-icon" :class="{ active: $parent.path == 'homepage' }" />
                        </div>
                        <span class="nav-text" :class="{ active: $parent.path == 'homepage' }">
                            {{ $t("dashboard") }}
                        </span>
                    </router-link>
                </b-col> -->
                <!-- <b-col cols="2">
                    <router-link tag="a" to="/web/wallet" class="nav-item-hold ">
                        <img :src="$parent.path == 'wallet' ? require('./assets/images/xlogo_footer/wallet_active.png') : require('./assets/images/xlogo_footer/wallet.png')"
                            class="nav-icon" :class="{ active: $parent.path == 'wallet' }" />
                        <span class="nav-text" :class="{ active: $parent.path == 'wallet' }">{{
                            $t("wallet")
                        }}</span>
                    </router-link>
                </b-col> -->



                <!-- <b-col cols="2" class="position-relative h-100">
                    <router-link tag="a" to="/web/trade" class="nav-item-hold ">
                        <div class="img-box centering">
                            <img src="./assets/images/xlogo_footer/trade.png" alt="" width="100%">
                        </div>
                        <img :src="require('./assets/images/xlogo_footer/ib_tree_active.png')" class="nav-icon opacity-0" />
                        <span class="nav-text mt-auto" :class="{ active: $parent.path == 'trade' }">{{
                            $t("trade")
                        }}</span>
                    </router-link>
                </b-col> -->
<!-- 
                <b-col cols="2">
                    <router-link tag="a" to="/web/ib_tree" class="nav-item-hold">
                        <img :src="$parent.path == 'ib_tree' ? require('./assets/images/xlogo_footer/ib_tree_active.png') : require('./assets/images/xlogo_footer/ib_tree.png')"
                            class="nav-icon" :class="{ active: $parent.path == 'ib_tree' }" />
                        <span class="nav-text" :class="{ active: $parent.path == 'ib_tree' }">{{
                            $t("ib_tree")
                        }}</span>
                    </router-link>
                </b-col> -->

                <!-- <b-col cols="2">
                    <router-link tag="a" to="/web/commission" class="nav-item-hold">
                        <img :src="$parent.path == 'commission' ? require('./assets/images/xlogo_footer/commission_active.png') : require('./assets/images/xlogo_footer/commission.png')"
                            class="nav-icon" :class="{ active: $parent.path == 'commission' }" />
                        <span class="nav-text" :class="{ active: $parent.path == 'commission' }">{{
                            $t("commission")
                        }}</span>
                    </router-link>
                </b-col>
            </b-row> -->
            <!-- fotter end -->
        </div>
    </div>
</template>
<script>
export default{

}
</script>