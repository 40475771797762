import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
import checkWithdraw from "./system/auth/canWithdraw.js";
Vue.use(Router);

// create new router

const routes = [

  {
    path: "/",
    redirect: "/web/homepage",
  },
  {
    path: "/web",
    component: () => import("./views"),
    redirect: "/web/homepage",
    children: [
      {
        path: "/web/homepage",
        beforeEnter: checkAuth,
        component: () => import("./views/home/homePage"),
      },
      {
        path: "/web/game",
        beforeEnter: checkAuth,
        component: () => import("./views/game/game"),
      },
      {
        path: "/web/promotion",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/promotion/promotion"),
      },
      {
        path: "/web/livechat",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/livechat/index"),
      },
      // {
      //   path: "/web/referral_link",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/referral/referral"),
      // },
      // {
      //   path: "/web/wallet",
      //   meta: { requiresAuth: true },
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/wallet/record/record_page"),
      // },

      // {
      //   path: "/web/trade",
      //   meta: { requiresAuth: true },
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/subscription/trade/trade"),
      // },
      // {
      //   path: "/web/ib_tree",
      //   meta: { requiresAuth: true },
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/team/memberTree"),
      // },
      // {
      //   path: "/web/commission",
      //   meta: { requiresAuth: true },
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/wallet/record/commission"),
      // },


      {
        path: "/web/manage",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage"),

        children: [
          {

            path: "/web/manage/account",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/profile/account.vue"),
          },
          {

            path: "/web/manage/downline",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/profile/team/memberTree"),
          },
          {

            path: "/web/manage/withdraw",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/profile/withdrawal"),
          },
          {

            path: "/web/manage/messages",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/profile/message"),
          }

        ]
      },

      {
        path: "/web/fund",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage"),

        children: [
          {

            path: "/web/fund/wallet_details",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/funds/wallet_details.vue"),
          },
          {

            path: "/web/fund/deposit",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/funds/deposit.vue"),
          },
          {

            path: "/web/fund/withdraw",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/funds/withdraw.vue"),
          },
          {

            path: "/web/fund/history",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/funds/history.vue"),
          },
          {

            path: "/web/fund/transfer",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/funds/transfer.vue"),
          },

        ]
      },


      {

        path: "/web/depositLink",
        beforeEnter: checkAuth,
        meta: { requiresAuth: true },
        component: () => import("./views/manage/funds/depositLink.vue"),
      },

      {
        path: "/web/security",
        meta: { requiresAuth: true },
        component: () => import("./views/manage"),
        beforeEnter: checkAuth,
        children: [
          {
            path: "change-password",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/security/change-password"),
          },

          {
            path: "change-sec-password",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/security/change-sec-password"),
          },

          {
            path: "forget-sec-password",
            beforeEnter: checkAuth,
            meta: { requiresAuth: true },
            component: () => import("./views/manage/security/forget-sec-password"),
          },


        ]
      },

    ]
  },
  {
    path: "/web/invite_link",
    meta: { requiresAuth: true },
    beforeEnter: checkAuth,
    component: () => import("./views/manage/invitePage"),
  },
  {
    path: "/web/transaction",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/transaction"),
    beforeEnter: checkAuth,
    children: [
      {
        path: "transaction",
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/transaction/transaction"),
      },
      // {
      //   path: "withdraw",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/withdraw"),
      // },
      {
        path: "deposit",
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/transaction/deposit"),
      },
      //   path: "deposit",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/deposit/depositBox"),
      // },
      // {
      //   path: "setCoin",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/setCoin"),
      // },
      // {
      //   path: "setBank",
      //   beforeEnter: checkAuth,
      //   component: () => import("./views/manage/transaction/withdraw/setBank"),
      // },

    ]
  },
  {
    path: "/web/kyc",
    beforeEnter: checkAuth,
    component: () => import("./views/manage/profile/kyc"),
  },
  {
    path: "/web/withdraw_info",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/transaction/withdraw"),
    beforeEnter: checkAuth,
    children: [
      {
        path: "withdraw",
        meta: { requiresAuth: true },
        beforeEnter: checkWithdraw,
        component: () => import("./views/manage/transaction/withdraw/withdraw"),
      },
      {
        path: "setCoin",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/withdraw/setCoin"),
      },
      {
        path: "setBank",
        meta: { requiresAuth: true },
        beforeEnter: checkAuth,
        component: () => import("./views/manage/transaction/withdraw/setBank"),
      },

    ]
  },

  {
    path: "/web/rank-details",
    meta: { requiresAuth: true },
    component: () => import("./views/wallet/record/rank_details"),
  },

  {
    path: "/web/set-sec-password",
    meta: { requiresAuth: true },
    component: () => import("./views/manage/security/set-sec-password"),
  },

  {
    path: "/web/forget-password",
    component: () => import("./views/manage/security/forget-password"),
  },


  {
    path: "/web/sessions",
    component: () => import("./views/sessions"),
    children: [
      {
        path: "login",
        component: () => import("./views/sessions/signIn"),
      },
      {
        path: "aboutUs",
        component: () => import("./views/sessions/aboutUs"),
      },
      {
        path: "tnc",
        component: () => import("./views/sessions/tnc"),
      },
    ]
  },
  {
    path: "/register",
    component: () => import("./views/sessions/signUp"),
  },

  {
    path: "*",
    component: () => import("./views/pages/notFound")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
// var liveChat = document.getElementById('chat-widget-container');
router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.


  if (from.path.includes('manage')) {
    next();

  } else {
    if (to.path) {
      // Start the route progress bar.

      NProgress.start();
      NProgress.set(0.1);
    }
    next();

  }
});

router.afterEach(() => {
  // Remove initial loading
  var content = document.getElementById('top');
  if (content) {
    content.scrollTo({ top: 0, behavior: "smooth" });
  }
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
