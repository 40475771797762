<template>
  <b-modal id="forget_pwd" centered :title="$t('forget_password')" hide-footer>
    <div class="text-left">
      <b-form @submit.prevent="_checkOTP">
        <div class="mx-2 mt-3">
          <b-form-group class="mt-0">
            <h4 class="font-weight-semibold text-14">{{ $t('mobile') }}</h4>
            <b-input-group>
              <template #prepend>
                <span class="pl-3 pr-2">
                  +6
                </span>
              </template>
              <b-form-input class="no-border" v-model="phone" type="number"
                :placeholder="$t(`enter_mobile_phone`)"></b-form-input>
              <template #append>
                <b-button variant="primary" @click="checkMobileIP" :disabled="startCount || sending">
                  {{ startCount ? timecount + 's' : !sending ? $t('getCode') : $t("loading...") }}
                </b-button>
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group class="mt-4">
            <h4 class="font-weight-semibold text-14">{{ $t('vcode') }}</h4>
            <b-form-input class="form-control " :placeholder="$t('vcode')" type="number" v-model="otp" required>
            </b-form-input>
          </b-form-group>

          <b-form-group class="mt-4">
            <h4 class="font-weight-semibold text-14">{{ $t('new_password') }}</h4>
            <b-form-input class="form-control " v-model="password" :placeholder="$t('new_password')" type="password"
              required></b-form-input>
          </b-form-group>

          <b-button class="d-block mt-4 ml-auto px-4" variant="primary" type="submit" :disabled="isLoading">{{
          isLoading ? $t("loading...") : $t("submit") }}</b-button>

        </div>
      </b-form>

    </div>
  </b-modal>
</template>

<script>
import {
  country_list,
  sendOtp,
  checkOtpWithoutToken,
  forgetPassword,
  checkMobileIp
} from "../../../system/request/api/api";
import { handleError } from "../../../system/request/handleRes";
export default {
  computed: {

  },
  components: {
  },
  data() {
    return {
      email: '',
      username: "",
      phone: "",
      country: "",
      otp: "",
      password: "",
      password_confirmation: "",
      timecount: 60,
      startCount: false,
      myVar: null,
      sending: false,
      isLoading: false,
      countryCode: "",
      countryOptions: [],
      country_id: "",
    };
  },
  props: ["success"],
  methods: {
    getInfo() {

      var info = JSON.parse(localStorage.getItem('info'));

      this.email = info.email ?? '';
      console.log('abc');
      console.log(info.email);
    },
    selectCountry(id, country_name) {
      this.country_id = id;
      document.getElementById("demo").innerHTML = country_name;
      this.$bvModal.hide("modal-otp");
      this.updateCode(id);
    },

    updateCode(id) {
      this.rows.forEach((item) => {
        if (id == item.id) {
          this.countryCode = item.country_code;
        }
      });
    },
    openCountry() {
      this.$bvModal.show("modal-otp");
    },
    getCountryList() {
      var result = country_list();
      var self = this;
      self.countryOptions = [];
      this.isLoading = true;
      result
        .then(function (value) {
          console.log(value.data);
          self.country = value.data.data[0].id;
          self.countryCode = value.data.data[0].country_code;
          document.getElementById("demo").innerHTML =
            self.$i18n.locale == "en"
              ? value.data.data[0].name_en
              : value.data.data[0].name;
          for (let i = 0; i < value.data.data.length; i++) {
            var jsonObject = {};
            jsonObject["value"] = value.data.data[i].id;
            jsonObject["text"] =
              self.$i18n.locale == "en"
                ? value.data.data[i].name_en
                : value.data.data[i].name;
            self.countryOptions.push(jsonObject);
            self.rows = value.data.data;
          }
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.isLoading = false;
        });
    },
    _checkOTP() {
      var self = this;
      let formData = new FormData();
      formData.append("mobile_no", '6' + this.phone);
      formData.append("otp", this.otp);
      var result = checkOtpWithoutToken(formData);

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.code == 0) {
            self.$root.makeToast("success", self.$t("OTP_OK"));
            self.submitForm();
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
        });
    },
    checkMobileIP() {
      if (this.phone.length <= 0) {
        this.$root.makeToast("danger", this.$t("phoneEmpty"));
      } else {
        this.sending = true;
        let formData = new FormData();
        formData.append("mobile_no", '6' + this.phone);
        var result = checkMobileIp(formData);
        var self = this;

        result
          .then(function (value) {
            console.log(value.data);
            if (value.data.error_code == '0') {
              self.getOTP();
            } else {
              self.$root.makeToast("danger", value.data.message);
            }
            // self.sending = false;
          })
          .catch(function (error) {
            self.$root.makeToast("warning", self.$t(handleError(error)));
            self.sending = false;
          });
      }
    },
    getOTP() {
      if (this.phone.length <= 0) {
        this.$root.makeToast("danger", this.$t("phoneEmpty"));
      } else {
        this.sending = true;
        let formData = new FormData();
        formData.append("mobile_no", '6' + this.phone);
        // formData.append("country_id", this.country_id);
        // formData.append("lang", this.$i18n.locale == "en" ? "en" : "cn");
        var result = sendOtp(formData);
        var self = this;

        result
          .then(function (value) {
            console.log(value.data);
            if (value.data.code == 0) {
              self.$root.makeToast("success", self.$t("otp_sent"));
              self.startCount = true;
              self.myVar = setInterval(() => {
                self.timecount -= 1;
                if (self.timecount == 0) {
                  self.timecount = 60;
                  clearInterval(self.myVar);
                  self.startCount = false;
                }
              }, 1000);
            } else {
              self.$root.makeToast("danger", value.data.message);
            }
            self.sending = false;
          })
          .catch(function (error) {
            self.$root.makeToast("warning", self.$t(handleError(error)));
            self.sending = false;
          });
      }
    },
    submitForm() {
      let formData = new FormData();
      var self = this;
      self.$root.isLoading = true;
      formData.append("username", '6' + this.phone);
      formData.append("password", this.password);
      var result = forgetPassword(formData);

      result
        .then(function (value) {
          console.log(value.data);
          if (value.data.error_code == 0) {
            self.$root.makeToast("success", self.$t(value.data.message));
            self.otp = "";
            self.password = "";
            self.password_confirmation = "";
            self.$bvModal.hide('forget_pwd');
          } else {
            self.$root.makeToast("danger", self.$t(value.data.message));
          }
          self.sending = false;
          self.$root.isLoading = false;
        })
        .catch(function (error) {
          self.$root.makeToast("warning", self.$t(handleError(error)));
          self.sending = false;
          self.$root.isLoading = false;
        });
    },
  },
  created() {
    // this.getInfo();
    // this.username = localStorage.getItem("username");
    // this.getCountryList();
  },
};
</script>
<style>
i.header-icon-country {
  font-size: 20px;
}

.middle.col-7 {
  margin: auto;
}

.middle.col-2 {
  margin: auto;
  text-align: right;
}

hr.line {
  margin-top: 5px;
  margin-bottom: 5px;
}

.overlay-text {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  color: #000;
  font-weight: 700;
  line-height: 1.5;
}
</style>