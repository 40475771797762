<template>
    <div>
        <div class="header_wrap">
            <!-- <div class="background-image"></div> -->
            <div class="content">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="logo cursor-pointer" @click="$router.push('/web/homepage')">
                        <img class="w-100" src="./assets/images/igame/logo.png" alt="">
                        <!-- <img :src="require('./assets/images/logo/logo.png')" alt="" width="100%"> -->
                    </div>
                    <div class="justify-content-start align-items-center flex-grow-1 mx-5 d-md-flex d-none"></div>
                    <div class="d-md-flex d-none justify-content-between align-items-center text-14"
                        v-if="!$route.path.includes('sessions') && !$route.path.includes('register') && !$route.path.includes('set-sec-password')">

                        <b-dropdown id="wallet" :text="$t('wallet')" variant="link"
                            toggle-class="nav-text text-decoration-none" filter no-caret>
                            <b-dropdown-text>
                                <div class="d-flex justify-content-between p-0">
                                    <div class="text-special text-16 font-weight-semibold">{{ $t('wallet') }}</div>
                                    <div class="text-special text-16 font-weight-semibold">{{ $t('Bal') }}</div>
                                </div>
                            </b-dropdown-text>
                            <b-dropdown-text>
                                <div class="w-100" style="border: 1px solid #E8C38F"></div>
                            </b-dropdown-text>
                            <b-dropdown-item>
                                <div class="d-flex text-14 font-weight-semibold justify-content-between">
                                    <div>
                                        <img src="../src/assets/images/igame/wallet_icon.png" class="mr-3">
                                        <span class="text-special mt-1">{{ $t('main_wallet') }}</span>
                                    </div>
                                    <span class="text-white text-right mt-1">{{ $root.userInfo.point }}</span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <div class="d-flex text-14 font-weight-semibold justify-content-between">
                                    <div>
                                        <img src="../src/assets/images/igame/wallet_icon.png" class="mr-3">
                                        <span class="text-special mt-1">{{ $t('referral_wallet') }}</span>
                                    </div>
                                    <span class="text-white text-right mt-1">{{ $root.userInfo.point2 }}</span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <div class="d-flex text-14 font-weight-semibold justify-content-between"
                                    v-for="item in $root.gameAmount" :key="item.id">
                                    <div>
                                        <img src="../src/assets/images/igame/wallet_icon.png" class="mr-3">
                                        <span style="color:#A3A3A3;">{{ item.game.game_name }}</span>
                                    </div>
                                    <span class="text-white text-right mt-1">{{ item.game_account.point }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                        <!-- <router-link tag="a" to="/web/wallet" class="">
                        <b-dropdown>
                            <span class="nav-text" :class="{ active: $parent.path == 'wallet' }">
                                {{ $t("wallet") }}
                            </span>
                        </b-dropdown>
                        </router-link> -->

                        <b-dropdown id="" :text="$t('funds')" variant="link"
                            toggle-class="nav-text text-decoration-none" no-caret>
                            <b-dropdown-item>
                                <router-link tag="a" to="/web/fund/wallet_details" class="">
                                    <div class="d-flex py-2">
                                        <img src="../src/assets/images/igame/wallet_icon.png" class="mr-3">
                                        <span class="text-special text-16 font-weight-bold">{{ $t('wallet') }}</span>
                                    </div>
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <router-link tag="a" to="/web/fund/deposit" class="">
                                    <div class="d-flex py-2">
                                        <img src="../src/assets/images/igame/deposit_icon.png" class="mr-3">
                                        <span class="text-special text-16 font-weight-bold">{{ $t('deposit') }}</span>
                                    </div>
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <router-link tag="a" to="/web/fund/withdraw" class="">
                                    <div class="d-flex py-2">
                                        <img src="../src/assets/images/igame/withdraw_icon.png" class="mr-3">
                                        <span class="text-special text-16 font-weight-bold">{{ $t('withdraw') }}</span>
                                    </div>
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <router-link tag="a" to="/web/fund/history" class="">
                                    <div class="d-flex py-2">
                                        <img src="../src/assets/images/igame/history_icon.png" class="mr-3">
                                        <span class="text-special text-16 font-weight-bold">{{
                        $t('transaction_history') }}</span>
                                    </div>
                                </router-link>
                            </b-dropdown-item>
                        </b-dropdown>
                        <!-- <router-link tag="a" to="/web/wallet" class="">
                            <span class="nav-text" :class="{ active: $parent.path == 'funds' }">
                                {{ $t("funds") }}
                            </span>
                        </router-link> -->

                        <b-dropdown id="" :text="$t('profile')" variant="link"
                            toggle-class="nav-text text-decoration-none" no-caret>
                            <b-dropdown-item>
                                <router-link tag="a" to="/web/manage/account" class="">
                                    <div class="d-flex py-2">
                                        <img src="../src/assets/images/igame/profile_icon.png" class="mr-3">
                                        <span class="text-special text-16 font-weight-bold">{{ $t('profile') }}</span>
                                    </div>
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <router-link tag="a" to="/web/manage/withdraw" class="">
                                    <div class="d-flex py-2">
                                        <img src="../src/assets/images/igame/bankdetails_icon.png" class="mr-3">
                                        <span class="text-special text-16 font-weight-bold">{{
                        $t('banking_details') }}</span>
                                    </div>
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-item>
                                <router-link tag="a" to="/web/manage/messages" class="">
                                    <div class="d-flex py-2">
                                        <img src="../src/assets/images/igame/msg_icon.png" class="mr-3">
                                        <span class="text-special text-16 font-weight-bold">{{ $t('message') }}</span>
                                    </div>
                                </router-link>
                            </b-dropdown-item>
                            <b-dropdown-text>
                                <div class="w-100" style="border: 1px solid #E8C38F"></div>
                            </b-dropdown-text>
                            <b-dropdown-item @click="$parent.logout">
                                <div class="d-flex py-2">
                                    <img src="../src/assets/images/igame/logout_icon.png" class="mr-3">
                                    <span class="text-special text-16 font-weight-bold">{{ $t('sign_out') }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                        <!-- <router-link tag="a" to="/web/manage/account" class="">
                            <span class="nav-text" :class="{ active: $parent.path == 'profile' }">
                                {{ $t("my_profile") }}
                            </span>
                        </router-link>    -->
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="ml-4" style="">
                            <b-dropdown id="dropdown-lang" right variant="link" toggle-class="my-dropdown">

                                <template #button-content>
                                    <!-- <img :src="require(`./assets/images/flag/${$i18n.locale}.png`)" alt="" width="20px"> -->
                                    <span class="text-14 text-uppercase">{{ $i18n.locale }}</span>
                                </template>
                                <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <!-- <div id="tooltip-button-1" class="text-20  ml-4 text-white" @click="show = !show">
                            <i class="fa-regular fa-bell"></i>
                        </div>

                        <b-tooltip :show.sync="show" target="tooltip-button-1" placement="bottom">
                            <div class="title-news text-left">
                                {{ $t('news') }}
                            </div>
                            <div class="title-content d-flex" @click="openNews(item)" v-for="item in $root.newsList"
                                :key="item.id">
                                <img :src="item.public_path" alt="" height="48px" width="48px">
                                <div class="content ml-2 text-left">
                                    <div class="text-14 font-weight-semibold mb-1 text-ellipsis">{{ item.title }}</div>
                                    <div class="text-secondary mb-1 max-line-2 text-14">{{ item.description }}</div>
                                    <div class="text-secondary text-12">{{ item.updated_at }}</div>

                                </div>

                            </div>
                        </b-tooltip> -->
                        <!-- <div class="text-14 text-white ml-4 d-md-block d-none">
                            <b-button variant="primary" class="text-14" @click="$router.push('/web/trade')">{{
                                $t('start_trading') }}</b-button>

                        </div>
                        <div class="text-20 text-white ml-4 d-md-block d-none">
                            <b-dropdown id="dropdown-lang" right variant="link" toggle-class="my-dropdown">

                                <template #button-content>
                                    <span class="text-white text-20 text-uppercase"><i
                                            class="fa-regular fa-circle-user"></i></span>
                                </template>
                                <b-dropdown-item href="#" @click="$router.push('/web/manage/account?type=1')">{{ $t("account_info")
                                }}</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$parent.logout">{{ $t('logout') }}</b-dropdown-item>
                            </b-dropdown>

                        </div>-->
                        <div class="text-20 ml-4 d-md-none d-block" v-b-toggle.sidebar-right style="color: #A3A3A3;">
                            <i class="fa-solid fa-bars"></i>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="header_wrap bg-special d-none d-md-block"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('register') && !$route.path.includes('set-sec-password')">
            <div class="content">
                <div class="d-flex justify-content-start align-items-center">
                    <router-link tag="a" to="/web/homepage" class="">
                        <span class="nav-text" :class="{ active: $parent.path == 'homepage' }">
                            {{ $t("home") }}
                        </span>
                    </router-link>
                    <router-link tag="a" to="/web/game" class="">
                        <span class="nav-text" :class="{ active: $parent.path == 'game' }">
                            {{ $t("game") }}
                        </span>
                    </router-link>
                    <router-link tag="a" to="/web/promotion" class="">
                        <span class="nav-text" :class="{ active: $parent.path == 'promotion' }">
                            {{ $t("promotion") }}
                        </span>
                    </router-link>
                    <router-link tag="a" to="/web/livechat" class="">
                        <span class="nav-text" :class="{ active: $parent.path == 'livechat' }">
                            {{ $t("livechat") }}
                        </span>
                    </router-link>
                    <div @click="checkToken">
                        <span class="nav-text">
                            {{ $t("referral_link") }}
                        </span>
                    </div>
                </div>
            </div>

        </div>
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow backdrop>

            <router-link tag="a" to="/web/homepage" class="">
                <span class="nav-text" :class="{ active: $parent.path == 'homepage' }">
                    <i class="fa-solid fa-house mr-3"></i>{{ $t("home") }}
                </span>
            </router-link>
            <router-link tag="a" to="/web/game" class="">
                <span class="nav-text" :class="{ active: $parent.path == 'game' }">
                    <i class="fa-solid fa-gamepad mr-3"></i>{{ $t("game") }}
                </span>
            </router-link>
            <router-link tag="a" to="/web/promotion" class="">
                <span class="nav-text" :class="{ active: $parent.path == 'promotion' }">
                    <i class="fa-solid fa-gift mr-3"></i>{{ $t("promotion") }}
                </span>
            </router-link>
            <router-link tag="a" to="/web/livechat" class="">
                <span class="nav-text" :class="{ active: $parent.path == 'livechat' }">
                    <i class="fa-solid fa-comments mr-3"></i>{{ $t("livechat") }}
                </span>
            </router-link>
            <a @click="$bvModal.show('modalReferral')">
                <span class="nav-text">
                    <i class="fa-solid fa-user-plus mr-3"></i>{{ $t("referral_link") }}
                </span>
            </a>

            <hr class="mb-4">

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/manage/account" class="">
                    <span class="nav-text" :class="{ active: $route.path.includes('manage') }">
                        <i class="fa-solid fa-user mr-3"></i>{{ $t("profile") }}
                    </span>
                </router-link>
            </div>


            <div class="text-left mb-4">
                <router-link tag="a" to="/web/fund/wallet_details" class="">
                    <span class="nav-text" :class="{ active: $parent.path == $route.path.includes('fund') }">
                        <i class="fa-solid fa-coins mr-3"></i> {{ $t("fund") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/security/change-password" class="">
                    <span class="nav-text" :class="{ active: $parent.path == 'change-password' }">
                        <i class="fa-solid fa-gear mr-3"></i>{{ $t("change-password") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <span class="nav-text logout" @click="$parent.logout">
                    <i class="fa-solid fa-right-from-bracket mr-3"></i>{{ $t("logout") }}
                </span>
            </div>

        </b-sidebar>
        <Referral></Referral>
    </div>

</template>
<script>
import Referral from './views/referral/referral.vue';
export default {
    data() {
        return {
            show: false,
        }
    },
    components: {
        Referral
    },
    methods: {
        checkToken() {
            var token = localStorage.getItem('gamingToken') ?? '';
            if (token.length > 0) {
                this.$bvModal.show('modalReferral')

            } else {
                this.$router.push('/web/sessions/login');
            }
        },
        logout() {
            localStorage.removeItem('gamingToken');
            localStorage.removeItem('info');
            this.$router.push('/web/sessions/login');
        },
        openNews(item) {
            this.show = !this.show;
            this.$root.newsItem = item;
            this.$bvModal.show('modal-news');

        }
    }

}
</script>