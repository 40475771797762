<template>
    <div>
        <b-modal id="modalReferral" ref="modalReferral" centered hide-header hide-footer>
            <div class="align-center" ref="box1">
                <div class="text-18 font-weight-semibold text-special mb-2">
                    {{ $t("referral_pages") }}
                </div>
                <div class="text-14 mb-4">
                    {{ $t('share_link') }}
                </div>
                <div class="text-left">
                    <div class="text-special text-14">
                        {{ $t('referral_title') }}
                    </div>
                    <div class="p-0">
                        <ul>
                            <li class="text-12">{{ $t('referral_text1') }}</li>
                            <li>{{ $t('referral_text2') }}</li>

                            <span class="text-10">{{ $t("referral_text3") }}</span>

                            <li>{{ $t('referral_text4') }} <span class="text-special">{{ $t('reward_challenge')
                                    }}</span>)</li>
                        </ul>
                    </div>
                    <div class="text-special text-14">
                        {{ $t('referral_title2') }}
                    </div>
                    <ul>
                        <li>{{ $t('free_myr50') }}</li>
                        <li>{{ $t('welcome_bonus60') }}</li>
                    </ul>
                </div>
                <div class="qr-code">
                    <qr-code :text="$root.inv_link" class="" :size="190"
                        style="border: 8px solid grey; border-radius:4px;"></qr-code>
                </div>
                <div class="p-2">
                    <!-- <img class="img-thumbnail img-fluid " src="../../assets/images/igame/qrcode.png" width="200px" style="border: 8px solid grey"> -->
                </div>
                <div class="d-flex my-3">
                    <div class="input-group">
                        <input class="form-control text-14 col-12 col-xs-8" v-model="$root.inv_link" readonly/>
                    </div>
                    <b-button class="bg-special text-14 col-2 col-xs-4" @click="copy($root.inv_link)">{{ $t('copy')
                        }}</b-button>
                </div>
                <hr>
                <div class="text-14 text-special font-weight-semibold my-4">
                    {{ $t("share_to_friend") }}
                </div>
                <div class="mt-2 py-1" block style="background: #383535; border-radius:8px;">
                    <b-row class="justify-content-center">
                        <b-col cols="2">
                            <span  @click="shareWhatsapp" style="cursor: pointer;">
                                <img src="../../assets/images/igame_referral/whatapps_icon.png" class="img-fluid">
                            </span>
                        </b-col>
                        <b-col cols="2">
                            <span  @click="shareLink" style="cursor: pointer;">
                                <img src="../../assets/images/igame_referral/share_icon.png" class="img-fluid">
                            </span>
                        </b-col>
                        <b-col cols="2">
                            <span  @click="shareLine" style="cursor: pointer;">
                                <img src="../../assets/images/igame_referral/line_icon.png" class="img-fluid">
                            </span>
                        </b-col>
                        <b-col cols="2">
                            <span  @click="shareTele" style="cursor: pointer;">
                                <img src="../../assets/images/igame_referral/telegram_icon.png" class="img-fluid">
                            </span>
                        </b-col>
                        <b-col cols="2">
                            <span  @click="shareMessenger" style="cursor: pointer;">
                                <img src="../../assets/images/igame_referral/messages_icon.png" class="img-fluid">
                            </span>
                        </b-col>
                    </b-row>
                </div>
                <b-button variant="primary" block class="mt-4 mb-2" @click="$bvModal.hide('modalReferral')">
                    <div class="text-white text-14">
                        {{ $t("done_close") }}
                    </div>
                </b-button>
                <!-- <div class="text-14 text-grey">
                    {{ this.address }}
                </div>
                <b-link @click="copy(address)">
                    <span class="dashboard-text2" style="color: #F3A204;">
                        <i class="fa-regular fa-copy"></i>
                        {{ $t("copy") }}
                    </span>
                </b-link> -->

            </div>
        </b-modal>
    </div>
</template>
<script>
import {
    getMemberInfo,
} from "@/system/request/api/api";
export default {
    watch: {
        // $route() {
        //     this.changePath();
        // },
    },
    data() {
        return {
            qrCls: "qrcode",
            size: 0,
            path: "",
            title: '',
            address: '',
            inv_link: "",
            ref_code: "",
            memberInfo: {
                username: ''
            }
        };
    },

    methods: {
        isMobile() {
            const minWidth = 768; // Minimum width for desktop devices
            return window.innerWidth < minWidth || screen.width < minWidth;
        },
        async shareWhatsapp() {
            if (!this.isMobile()) {
                const shareData = {
                    title: "Gaming",
                    text: this.shareText,
                    url: this.$root.inv_link,
                };
                try {
                    navigator.share(shareData);

                } catch (error) {
                    alert(error);


                }
                return;
            }
            const shareUrl = `whatsapp://send?text=${encodeURIComponent(this.shareText)}%20${encodeURIComponent(this.$root.inv_link)}`;

            window.location = shareUrl;
        },
        async shareTele() {
            if (!this.isMobile()) {
                const shareData = {
                    title: "Gaming",
                    text: this.shareText,
                    url: this.$root.inv_link,
                };
                try {
                    navigator.share(shareData);

                } catch (error) {
                    alert(error);


                }
                return;
            }
            const shareUrl = `https://telegram.me/share/url?url=${encodeURIComponent(this.$root.inv_link)}&text=${encodeURIComponent(this.shareText)}`;

            window.location = shareUrl;
        },
        async shareLine() {
            if (!this.isMobile()) {
                const shareData = {
                    title: "Gaming",
                    text: this.shareText,
                    url: this.$root.inv_link,
                };
                try {
                    navigator.share(shareData);

                } catch (error) {
                    alert(error);


                }
                return;
            }
            const shareUrl = `line://msg/text/${encodeURIComponent(this.$root.inv_link)}`;

            window.location = shareUrl;
        },
        async shareMessenger() {
            if (!this.isMobile()) {
                const shareData = {
                    title: "Gaming",
                    text: this.shareText,
                    url: this.$root.inv_link,
                };
                try {
                    navigator.share(shareData);

                } catch (error) {
                    alert(error);


                }
                return;
            }
            const shareUrl = `fb-messenger://share/?link=${encodeURIComponent(this.$root.inv_link)}`;

            window.location = shareUrl;
        },
        async shareLink() {
            const shareData = {
                title: "Gaming",
                text: this.shareText,
                url: this.$root.inv_link,
            };
            try {
                navigator.share(shareData);

            } catch (error) {
                alert(error);


            }
            return;
            
            
        },
        getInfo() {
            var token = localStorage.getItem('gamingToken') ?? '';
            console.log(token);
            if (token.length <= 0) {
                return;
            }
            var self = this;
            self.isLoading = true;
            var result = getMemberInfo();
            self.inv_link = location.origin + "/register?ref_id=";
            result
                .then(function (value) {
                    self.isLoading = false;
                    if (value.data.error_code == '0') {
                        self.userInfo = value.data.data;
                        self.ref_code = value.data.data.refferal_code;
                        self.inv_link = self.inv_link + self.ref_code;

                    } else {
                        self.makeToast("danger", self.$t(value.data.message), 'danger');

                    }



                })
            // .catch(function (error) {
            // self.makeToast("warning", self.$t(handleError(error)), 'danger');
            // });
        },
        copy(value) {
            navigator.clipboard.writeText(value)
                .then(() => {
                    alert(`${this.$t('copySuccess')}: ` + value);
                })
                .catch((error) => {
                    alert(error);
                });
        },
    },
    created() {
        var title = this.$route.path.split('/');
        this.title = title[title.length - 1];
        // this.getInfo();

    },
    mounted() {
        // let width = this.$refs.box1.clientWidth;
        // this.size = width * 0.5;

    }
};
</script>


<style lang="scss" scoped>
li {
    font-size: 12px;
    padding: 5px 0 0 0;
}
</style>
