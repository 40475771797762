<template>
  <div id="app">
    <!-- <div class="gradient-bg">
      <div class="blur-1"></div>
      <div class="blur-2"></div>
      <div class="blur-3"></div>
    </div> -->
    <div v-if="$root.isLoading" class="loading">
      <div class="loading-box">

        <div class="loading-effect" style="top: 49%;left: 50%;">
          <!-- <div class="icon-button" :style="{ 'background-image': 'url(' + logo + ')' }" style="height: 3.5rem;width: 3.5rem;">
          </div> -->
        </div>
        <div class="loading-effect">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner">

          </b-spinner>

        </div>
        <div class="loading-effect" style="top: 80%;">
          {{ $t('loading...') }}

        </div>
      </div>
    </div>
    <Header v-if="!$route.path.includes('depositLink')"></Header>

    <router-view class="flex-grow-1" style="overflow-y: scroll !important;"></router-view>
    <Footer></Footer>
    <PasswordModal></PasswordModal>
    <ModalError></ModalError>
    <b-modal id="modal-news" hide-footer>

      <div class="">
        <img :src="$root.newsItem.public_path" alt="" height="138px" width="100%" style="object-fit: cover;" class="rounded-1">
        <div class="text-left mt-3">
          <div class="text-20 font-weight-semibold mb-1">{{ $root.newsItem.title }}</div>
          <div class="text-secondary text-12 mb-2">{{ $root.newsItem.updated_at }}</div>
          <div class="mb-1 text-14">{{ $root.newsItem.description }}</div>

        </div>

      </div>

    </b-modal>

<ForgetPassword></ForgetPassword>



  </div>
</template>

<script>
import Header from './header.vue';
import Footer from './footer.vue';
import ModalError from './errorMsg.vue'
import PasswordModal from './views/manage/security/passwordBUS.vue';
import ForgetPassword from './views/manage/security/forget-password.vue';
export default {
  components: {
    Header, Footer, PasswordModal, ModalError, ForgetPassword
  },
  watch: {
    $route() {
      var title = this.$route.path.split('/');
      this.title = title[title.length - 1];
    this.path = this.title;

    }
  },
  name: 'COH',
  data() {
    return {
      screenWidth: 0,
      title: '',
      path: '',
      username: '',
      address: '',
      logo: require('./assets/images/logo/logo-square.png')
    }
  },
  methods: {
    copy(value) {
            navigator.clipboard.writeText(value)
                .then(() => {
                    alert(`${this.$t('copySuccess')}: ` + value);
                })
                .catch((error) => {
                    alert(error);
                });
    },
    logout() {
      localStorage.removeItem('gamingToken');
      localStorage.removeItem('info');
      this.$router.push('/web/sessions/login');
    },
  },
  computed: {
    computedWidth: function () {
      return this.screenWidth + 'px';
    }
  },
  created() {
    // this.screenWidth = screen.width;

  },
  mounted() {
    var title = this.$route.path.split('/');
    this.title = title[title.length - 1];

    this.path = this.title;
    // var info = JSON.parse(localStorage.getItem('info'));

    // this.$root.username = info.username;
    // console.log(this.$root.username);

    // this.$root.getGame();
    this.$root.getNewsList();
    this.$root.getInfo();
    

  }
}
</script>

<style>
#app {
  font-family: 'Manrope' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
